import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySymbol'
})
export class CurrencySymbolPipe implements PipeTransform {
  transform(currencyCode: string | undefined): string {
    if (!currencyCode) return '';
    
    switch (currencyCode) {
      case 'USD':
        return '$';
      case 'KZT':
        return '₸';
      default:
        return currencyCode;
    }
  }
} 