import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";

import { MaterialModule } from "./material.module";
import { FeatherIconsModule } from "./feather-icons.module";
import { NgxMaskModule } from "ngx-mask";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { CurrencySymbolPipe } from "./pipes/currency-symbol.pipe";

@NgModule({
  declarations: [SpinnerComponent, CurrencySymbolPipe],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    FeatherIconsModule,
    MaterialModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MaterialModule,
    FeatherIconsModule,
    NgxMaskModule,
    SpinnerComponent,
    CurrencySymbolPipe,
  ],
})
export class SharedModule {}
