import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { AdminGuard } from './core/guard/admin.guard';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { UsersComponent } from './components/users/users.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'admin',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'cash-office',
        loadChildren: () =>
          import('./components/cash-office/cash-office.module').then(
            (m) => m.CashOfficeModule
          ),
      },
      {
        path: 'balance',
        loadChildren: () =>
          import('./components/balans/balance.module').then(
            (m) => m.BalanceModule
          ),
      },
      {
        path: 'debts',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('./components/debts/debts.module').then((m) => m.DebtsModule),
      },
      {
        path: 'revision',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('./components/revision/revision.module').then(
            (m) => m.RevisionModule
          ),
      },
      {
        path: 'users',
        canActivate: [AdminGuard],
        component: UsersComponent,
      },

      {
        path: 'reports',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('./components/reports/report.module').then(
            (m) => m.ReportModule
          ),
      },
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: '**', component: Page404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
