import { Store } from "./user";

export class Dictionary {
  id: number = null;
  code: string = null;
  nameKz: string = null;
  nameQq: string = null;
  nameRu: string = null;
  nameEn: string = null;
  referenceId?: number = null;
}

export class Person {
  address: string = null;
  email: string = null;
  firstName: string = null;
  id: number = null;
  lastName: string = null;
  middleName: string = null;
  telephone: string = null;
  type?: { id: number; name: string; type: string } = null;
  fullName: string = null;
}

export class ExchangeRate {
  id: number = null;
  actualDate: string = null;
  value: string | number = null;
  currency: Dictionary = null;
}

export class Product {
  actualPrice: number = null;
  color: Dictionary = null; // цвет
  description: string = null; // примечание
  id: number = null;
  imei: string = ""; // имей код
  memory: Dictionary = null; // память
  model: Dictionary = null; // модель
  productPostings: Posting[] = [];
  family: Dictionary = null; //семейство
  ram: Dictionary = null; //
  state: string = null;
  status: string = null;
  store: Store = null;
  provider: Dictionary = null;
  product_type: string = null;
  isDuty: boolean = false;
  price: number = null;
  currency: Dictionary = null;

  partDuty?: number | string;
  note?: string;
  dutyReturnDate?: Date | string;
  productSales?: Product[] = [];
}

export class Posting {
  currency: Dictionary = null;
  description: string = null;
  id: number = null;
  isDuty: boolean = false;
  price: number = null;
  productId: number = null;
  provider: Person = null;
}

export class ProductHistory {
  flowType: string = null;
  id: number = null;
  product: Product = null;
  store: Store = null;
  lastModifiedDate: string;
  sourceId: number;
  status: string;
  user: Person;
  oldValue?: number | string;
  newValue?: number | string;
}

export class ProductSaleHistory {
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string | null;
  lastModifiedDate: string | null;
  id: number;
  sales: Product[];
  client: Person;
  seller: Person;
  totalSum: number;
  description: string;
}

export interface ProdcutPostingHistory {
  createdBy: string;
  createdByPerson: Person;
  createdDate: string;
  currency: Dictionary;
  description: string;
  id: number;
  lastModifiedBy: string;
  lastModifiedDate: string;
  postings: Array<Posting>;
  provider: Person;
  totalSum: number;
}

export interface ProductReturnHistory {
  createdBy: string;
  createdDate: string;
  description: string;
  id: number;
  returns: Array<{
    description: string;
    id: number;
    productId: number;
    productSaleId: number;
    returnDateTime: string;
  }>;
}

export interface BarcodeSaveDto {
  code: string;
  colorId: number;
  id: number;
  memoryId: number;
  modelId: number;
  productTypeId: number;
  familyId: number;
}

export interface Barcode {
  code: string;
  color: Dictionary;
  id: number;
  memory: Dictionary;
  model: Dictionary;
  family: Dictionary;
  productType: Dictionary;
}
